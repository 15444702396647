import { ClientCode } from "../IOC/ClientCode";




export class ModuleRpcProxy {

    private moduleUrl: string;

    public constructor (moduleUrl: string) {
        if (moduleUrl.substring(0, 1) != "/") {
            moduleUrl = "/" + moduleUrl;
        }

        this.moduleUrl = moduleUrl;
    }

    public call (method: string, params: Array<any>): Promise<any> {
        var jsonParams = JSON.stringify(params);
        if (params.length == 0) {
            jsonParams = "";
        }

        var url = this.moduleUrl + '/' + method;
        if (this.moduleUrl == '/') {
            url = '/' + method;
        }
        
        return new Promise<any>(function (resolve, reject) {
            $.ajax({
                type: 'POST',
                url: url,
                contentType: 'application/json',
                processData: false,
                headers: {
                    "X-MWC-ModuleRpc": "V1"
                },
                data: jsonParams,
                success: async function (result) {
                    if (result) {
                        if (result.__exception) {
                            // reject/exception
                            reject("Server exception: " + result.__exception);
                        }else if (result.redirect) {
                            // redirect, we won't resolve the await since we'll leave the page anyway. 
                            window.location.href = result.redirect;
                        }else {
                            await LoadResources(result.styles, result.scriptlinks);
                            
                            if (result.pagemessages) {
                                for (var msg of result.pagemessages) {
                                    InsertPageMessage(msg.Type, msg.Message);
                                }
                            }

                            if (result.scriptblock) {
                                eval(result.scriptblock);
                            }

                            if (result.clientinvocations) {
                                for (var call of result.clientinvocations) {
                                    ClientCode.Execute(call.ServiceKey, call.MethodName, call.Parameters);
                                }
                            }

                            // return the result / resolve the await
                            if (result.ajaxresult) {
                                resolve(result.ajaxresult);
                            }else {
                                resolve(undefined);
                            }
                        }
                    }else{
                        // reject/exception
                        reject("RPC call didn't return a result");
                    }
                },
                error: function (data) {
                    // replace the full document with the error page
                    var doc = document.open();
                    doc.write(data.responseText);
                    document.close();
                }
            });
        });
    }
};



async function LoadResources (styles: string[], scripts: string[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        
        var stylestoload: string[] = [];
        if (styles) {
            var existingStyles = $('head link[rel*="stylesheet"]');
            for (var s of styles) {
                var exists = existingStyles.filter('[href*="' + StripVersionTag(s) + '"]');
                if (exists.length == 0) {
                    stylestoload.push(s);
                }
            }
        }

        var linkstoload: string[] = [];
        if (scripts) {
            var existingScripts = jQuery('head script');
            for (var s of scripts) {
                var exists = existingScripts.filter('[src*="' + StripVersionTag(s) + '"]');
                if (exists.length == 0) {
                    linkstoload.push(s);
                }
            }
        }

        var totalResources = stylestoload.length + linkstoload.length;
        if (totalResources == 0) {
            resolve();
        }else{
            var waiter = new ResourceAwaiter(totalResources, resolve);

            var headID = document.getElementsByTagName("head")[0];
            for (var i in stylestoload) {
                var newstyle = document.createElement('link');
                newstyle.rel = 'stylesheet';
                newstyle.href = stylestoload[i];
                newstyle.onload = () => waiter.Hit();
                headID.appendChild(newstyle);
            }
            for (var i in linkstoload) {
                var newScript = document.createElement('script');
                newScript.type = 'text/javascript';
                newScript.src = linkstoload[i];
                newScript.onload = () => waiter.Hit();
                headID.appendChild(newScript);
            }
        }
    });
}

function StripVersionTag(path): string {
    var strip = path.replace(/^\/v[0-9]{12,12}\//i, "/");
    return strip;
}

class ResourceAwaiter {

    public constructor (awaitingLinks, callback: () => void) {
        this.total = awaitingLinks;
        this.callback = callback;
    }

    private total: number;
    private callback: () => void;
    private current: number = 0;
    private completed: boolean = false;

    public Hit(): void {
        this.current = this.current + 1;
        if (this.current >= this.total && !this.completed) {
            this.callback();
            this.completed = true;
        }
    }
}

function InsertPageMessage(type: string|number, message: string) {
    if ($().toastmessage) {
        var sticky = false;
        var typestr = "notice";

        switch (type) {
            case 'Info':
            case 0:
                typestr = "notice"; sticky = false; break;
            case 'Warning':
            case 2:
                typestr = "warning"; sticky = true; break;
            case 'Error':
            case 3:
                typestr = "error"; sticky = true; break;
            case 'Success':
            case 1:
                typestr = "success"; sticky = false; break;
            case 'Wait':
            case 4:
                typestr = "wait"; sticky = true; break;

            default: typestr = "notice"; sticky = false; break;
        }

        $().toastmessage('showToast', {
            text: message,     // content of the item
            sticky: false,               // should the toast item sticky or not?
            position: 'middle-center',       // top-left, top-center, top-right, middle-left, middle-center, middle-right
            // Position of the toast container holding different toast.
            // Position can be set only once at the very first call,
            // changing the position after the first call does nothing
            type: typestr,        // notice, warning, error, success
            stayTime: 10000,
            close: function () { }
        });
    }else{
        throw "Toastmessage plugin is not loaded";
    }
}