import { ServiceDescriptor } from "./ServiceDescriptor";
import { ServiceProxy } from "./ServiceProxy";

export interface IServiceClient {
    Dispose(): void;
}

export const ServiceFactory = {
    CreateServiceObject (serviceUrl: string, meta: ServiceDescriptor): any {
        var proxy = new ServiceProxy(serviceUrl);

        var result = { };
        for (var i = 0; i < meta.methods.length; i++) {
            (function (i) {
                var mname = meta.methods[i];
                result[mname] = function () { return proxy.call(mname, Array.prototype.slice.call(arguments)); }
            })(i);
        }
        for (var i = 0; i < meta.events.length; i++) {
            (function (i) {
                var mname = meta.events[i];
                result[mname] = function (callback) { return proxy.addevent(mname, callback); }
            })(i);
        }

        result["Dispose"] = function () {
            proxy.dispose();
        };

        return result;
    }
}