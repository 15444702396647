import { ServiceDescriptor } from "./ServiceDescriptor";
import { ServiceEnvironment } from "./ServiceEnvironment";

class ServiceGlobals {
    public proxies: Record<string, ServiceProxy> = { }
    public eventListeners: Record<string, Record<string, (args: any) => void>> = { }
    public sigrHub: SignalR.Hub.Proxy = null;
    public sigrActionQueue: Array<(hub: SignalR.Hub.Proxy) => void> = [ ];

    public RegisterProxy (serviceUrl: string, proxy: ServiceProxy): string {
        var num = 1;
        while (this.proxies[this.GetProxyId(serviceUrl, num)] !== undefined) {
            num++;
        }

        var id = this.GetProxyId(serviceUrl, num);
        this.proxies[id] = proxy;
        return id;
    }

    public DeregisterProxy (proxyId: string): void {
        delete this.proxies[proxyId];
    }

    public RegisterEventListener (eventUrl: string, proxyId: string, callback: (args: any) => void): void {
        var cblist = this.eventListeners[eventUrl];
        if (!cblist) {
            cblist = { };
            this.eventListeners[eventUrl] = cblist;
        }

        cblist[proxyId] = callback;
    }

    public DeregisterEventListener (eventUrl: string, proxyId: string): void {
        var cblist = this.eventListeners[eventUrl];
        if (cblist) {
            delete cblist[proxyId];
        }
    }

    private GetProxyId (serviceUrl: string, num: number): string {
        return `${serviceUrl}[${num}]`;
    }
}


const GLOBAL_KEY: string = "metades.web.core/ServiceProxy/globals";
if (!window[GLOBAL_KEY]) {
    window[GLOBAL_KEY] = new ServiceGlobals();
}
var global: ServiceGlobals = window[GLOBAL_KEY];


function invokeSigr(action: (hub: SignalR.Hub.Proxy) => void) {
    if (global.sigrActionQueue == null) {
        action(global.sigrHub);
    } else {
        global.sigrActionQueue.push(action);
    }

    if (global.sigrHub == null) {
        var connection = $.hubConnection("/_mwc_signalr");
        var hub = connection.createHubProxy("mwc_service_hub");
        global.sigrHub = hub;

        hub.on("mwc_service_event", function (msg) {
            var url = msg.url;
            var listener = global.eventListeners[url];
            if (listener) {
                for (var key in listener) {
                    listener[key](msg.args);
                }
            }
        });

        connection.start().done(function () {
            for (var i = 0; i < global.sigrActionQueue.length; i++) {
                global.sigrActionQueue[i](hub);
            }
            global.sigrActionQueue = null;
        });
    }
}

export class ServiceProxy {

    private serviceUrl: string;
    private appid: string;
    private appSubpath: string;
    private proxyId: string;
    private eventUrls: string[] = [ ];

    public constructor (serviceUrl: string) {
        if (serviceUrl.substring(0, 1) != "/") {
            serviceUrl = "/" + serviceUrl;
        }

        this.serviceUrl = serviceUrl;
        this.appid = ServiceEnvironment.AppId;
        this.appSubpath = ServiceEnvironment.AppSubpath;
        this.proxyId = global.RegisterProxy(serviceUrl, this);
    }

    public call (method: string, params: Array<any>): Promise<any> {
        var jsonParams = JSON.stringify(params);
        if (params.length == 0) {
            jsonParams = "";
        }

        var url = this.serviceUrl + '/' + method;
        if (this.appSubpath) {
            url = '/' + this.appSubpath + url;
        }
        return new Promise<any>(function (resolve, reject) {
            $.ajax({
                type: 'POST',
                url: url,
                contentType: 'application/json',
                processData: false,
                data: jsonParams,
                success: function (result) {
                    if (result && result.__exception) {
                        reject("Server exception: " + result.__exception);
                    }else{
                        resolve(result);
                    }
                }
            });
        });
    }

    public addevent (eventname: string, callback: (args: any) => void): Promise<void> {
        var appid = this.appid;
        var url = this.serviceUrl + '/' + eventname;
        this.eventUrls.push(url);
        global.RegisterEventListener(url, this.proxyId, callback);
        return new Promise<void>(function (resolve) {
            invokeSigr(function (hub) {
                hub.invoke('register', appid, url).done(function () {
                    resolve();
                });
            });
        });
    }

    public dispose (): void {
        for (var item of this.eventUrls) {
            global.DeregisterEventListener(item, this.proxyId);
        }
        global.DeregisterProxy(this.proxyId);
        
    }

};