


export class ServiceReference<T> {

    public readonly ServiceKey: string;
 
    public constructor(serviceKey: string) {
       this.ServiceKey = serviceKey;
    }
    
}

export class TypedServiceContainer<T> {

    private readonly ServiceKey: string;
    private readonly ServiceContainer: ServiceContainer;

    public constructor (serviceContainer: ServiceContainer, serviceKey: string) {
        this.ServiceKey = serviceKey;
        this.ServiceContainer = serviceContainer;
    }

    public Register (service: T): void {
        this.ServiceContainer.Register(this.ServiceKey, service);
    }

    public Replace (service: T): void {
        this.ServiceContainer.Replace(this.ServiceKey, service);
    }

    public Deregister (service: T): void {
        this.ServiceContainer.Deregister(this.ServiceKey, service);
    }

    public DeregisterAll (): void {
        this.ServiceContainer.DeregisterAll(this.ServiceKey);
    }

    public GetServices (): T[] {
        return this.ServiceContainer.GetServices(this.ServiceKey);
    }

    public GetSingleService (): T {
        return this.ServiceContainer.GetSingleService(this.ServiceKey);
    }
}

export class ServiceContainer {

    private services: Record<string, any[]> = { };

    public For<T> (ref: ServiceReference<T>): TypedServiceContainer<T> {
        return new TypedServiceContainer<T>(this, ref.ServiceKey);
    }

    public Register<T> ( name: string, service: any): void {
        if (this.services[name] === undefined) {
            this.services[name] = [ service ];
        }else if (this.services[name].indexOf(service) < 0) {
            this.services[name].push(service);
        }
    }

    public Replace (name: string, service: any): void {
        this.services[name] = [ service ];
    }

    public Deregister (name: string, service: any): void {
        if (this.services[name] == undefined) {
            return;
        }

        var serviceList = this.services[name];
        var index = serviceList.indexOf(service);
        if (index >= 0) {
            if (serviceList.length == 1) {
                this.DeregisterAll(name);
            }else{
                serviceList.splice(index, 1);
            }
        }
    }

    public DeregisterAll (name: string): void {
        delete this.services[name];
    }

    public GetServices (name: string): any[] {
        if (this.services[name] === undefined) {
            return [ ];
        }else{
            return this.services[name];
        }
    }

    public GetSingleService (name: string): any {
        if (this.services[name] === undefined) {
            return null;
        }else{
            return this.services[name][0];
        }
    }

}

export const GlobalServiceContainer: ServiceContainer = new ServiceContainer();