import { ClientCode } from './IOC/ClientCode';
import { GlobalServiceContainer } from './IOC/ServiceContainer';
//import { ModuleActionDescriptor } from './ModuleInfrastructure/ActionDescriptor';


export { ServiceDescriptor } from './ServiceInfrastructure/ServiceDescriptor';
export { ServiceFactory, IServiceClient } from './ServiceInfrastructure/ServiceFactory';
export { ServiceProxy } from './ServiceInfrastructure/ServiceProxy';
export { ServiceContainer, GlobalServiceContainer, ServiceReference } from './IOC/ServiceContainer';
export { ClientCode } from './IOC/ClientCode';
export { ModuleRpcProxy } from './ModuleInfrastructure/ModuleRpcProxy';
export { ModuleActionDescriptor } from  './ModuleInfrastructure/ActionDescriptor';
export { ModuleRpcConnector } from './ModuleInfrastructure/ModuleRpcConnector';

window["mwcGlobalServiceContainer"] = GlobalServiceContainer;
window["mwcClientCode"] = ClientCode;