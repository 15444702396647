
export const ServiceEnvironment = {
    get AppId (): string {
        // global variable for app key, passed in as script paramter
        var appid = window["mwc_service_meta_appid"];
        if (!appid)
            throw "mwc_service_meta_appid global variable was not defined";
        else
            return appid;
    },

    /** Without leading and trailing slash */
    get AppSubpath (): string {
        // global variable for app key, passed in as script paramter
        var appid = window["mwc_service_meta_appsubpath"];
        if (appid === undefined)
            throw "mwc_service_meta_appsubpath global variable was not defined";
        else
            return appid;
    }
};