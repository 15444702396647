import { GlobalServiceContainer, ServiceContainer } from "./ServiceContainer";


export const ClientCode = {

    Execute (serviceName: string, method: string, args: any[], serviceContainer?: ServiceContainer): void {
        if (serviceContainer === undefined) {
            serviceContainer = GlobalServiceContainer;
        }
        
        var services = serviceContainer.GetServices(serviceName);
        for (var svc of services) {
            svc[method](...args);
        }
    },

    ExecuteSingle (serviceName: string, method: string, args: any[], serviceContainer?: ServiceContainer): void {
        if (serviceContainer === undefined) {
            serviceContainer = GlobalServiceContainer;
        }
        
        var service = serviceContainer.GetSingleService(serviceName);
        service[method](...args);
    }

}